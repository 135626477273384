var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('sidebar',{ref:"sidebar",staticClass:"sidebar",attrs:{"title":_vm.$t('ems.control.sidebarTitle', { method: _vm.formData.methodName }),"subtitle":_vm.$t('ems.control.sidebarSubtitle', {
        client: _vm.client ? _vm.client.clientName : ''
      })},on:{"closedEvent":function($event){return _vm.onSidebarClosed()}},scopedSlots:_vm._u([{key:"sidebar-content",fn:function(){return [(_vm.driver)?_c('b-form',{on:{"submit":_vm.makeActionFromForm}},[_vm._l((_vm.driver.getEventParameterHandler(
            _vm.formData.methodName
          )),function(param){return _c('div',{key:param.name},[_c('b-form-group',{attrs:{"label":'Value for ' + param.name + ':',"label-for":'custom-parameter-' + param.name}},[(_vm.driver.getEventValues(_vm.formData.methodName))?_c('b-form-select',{attrs:{"value":null,"options":_vm.driver.getEventValues(_vm.formData.methodName)},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('ems.control.setOption'))+" ")])]},proxy:true}],null,true),model:{value:(_vm.formData.form[param.name]),callback:function ($$v) {_vm.$set(_vm.formData.form, param.name, $$v)},expression:"formData.form[param.name]"}}):_c('b-form-input',{attrs:{"id":'custom-parameter-' + param.name},model:{value:(_vm.formData.form[param.name]),callback:function ($$v) {_vm.$set(_vm.formData.form, param.name, $$v)},expression:"formData.form[param.name]"}}),_c('driver-action-ui',{attrs:{"driver":_vm.driver,"client":_vm.client,"parameter-name":param.name,"action-name":_vm.formData.methodName},on:{"valuePicked":(data) => {
                  _vm.onValuePicked(param.name, data.value)
                }}})],1)],1)}),_c('b-button',{staticClass:"my-4",attrs:{"type":"submit","variant":"success","block":""}},[_vm._v(" Send ")])],2):_vm._e()]},proxy:true}])}),(_vm.isDataComplete)?_c('div',{staticClass:"wrap mt-5"},[_c('div',{staticClass:"profile-info"},[_c('h2',[_vm._v(_vm._s(_vm.client.clientName))]),_c('div',[_c('b',[_vm._v("Status: ")]),_c('b',{class:'text-' + _vm.healthData.variant},[_vm._v(_vm._s(_vm.healthData.message))]),(_vm.statusString)?_c('b',{class:'text-' + _vm.statusString.toLowerCase().includes('error')
              ? 'danger'
              : _vm.statusString.toLowerCase().includes('unknown')
                ? 'secondary'
                : 'success'},[_vm._v(_vm._s(_vm.statusString))]):_vm._e()]),_c('br'),_c('div',[_c('b',[_vm._v("ID: ")]),_vm._v(_vm._s(_vm.client.clientId))]),_c('div',[_c('b',[_vm._v("Info: ")]),_vm._v(_vm._s(_vm.driver.info))]),_c('div',[_c('b',[_vm._v("Alias: ")]),_vm._v(_vm._s(_vm.driver.alias))]),(_vm.clientError)?_c('b-alert',{staticClass:"mt-3 text-center",attrs:{"variant":"danger","show":""}},[_c('b-icon-exclamation-diamond'),_c('b',[_vm._v("Error:")]),_vm._v(" "+_vm._s(_vm.clientError)+" ")],1):_vm._e()],1)]):_vm._e(),(_vm.isDataComplete)?_c('div',{staticClass:"wrap mt-4"},[_c('div',{staticClass:"profile-info"},[_c('h3',[_vm._v("Methods")]),_vm._l((_vm.driver.signaturesList),function(action){return _c('b-button',{key:action.method,staticClass:"m-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.makeAction(action.method)}}},[_vm._v(" "+_vm._s(action.method)+" ")])})],2)]):_vm._e(),(_vm.isDataComplete)?_c('div',{staticClass:"wrap mt-4"},[_c('div',{staticClass:"profile-info"},[_c('h3',[_vm._v("Logging")]),_c('b-form-textarea',{attrs:{"size":"sm","id":'log-' + _vm.client.clientId,"rows":"25","disabled":""}})],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }