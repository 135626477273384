<!--
  - Copyright (C) 2024. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
  -->

<template>
  <div>
    <!-- BEGIN: sidebar for execution params -->
    <sidebar
      class="sidebar"
      ref="sidebar"
      :title="$t('ems.control.sidebarTitle', { method: formData.methodName })"
      :subtitle="
        $t('ems.control.sidebarSubtitle', {
          client: client ? client.clientName : ''
        })
      "
      v-on:closedEvent="onSidebarClosed()"
    >
      <template v-slot:sidebar-content>
        <b-form v-if="driver" @submit="makeActionFromForm">
          <div
            v-for="param in driver.getEventParameterHandler(
              formData.methodName
            )"
            :key="param.name"
          >
            <b-form-group
              :label="'Value for ' + param.name + ':'"
              :label-for="'custom-parameter-' + param.name"
            >
              <b-form-select
                v-if="driver.getEventValues(formData.methodName)"
                :value="null"
                v-model="formData.form[param.name]"
                :options="driver.getEventValues(formData.methodName)"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>
                    {{ $t('ems.control.setOption') }}
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-input
                v-else
                :id="'custom-parameter-' + param.name"
                v-model="formData.form[param.name]"
              ></b-form-input>
              <driver-action-ui
                v-bind:driver="driver"
                v-bind:client="client"
                v-bind:parameter-name="param.name"
                v-bind:action-name="formData.methodName"
                v-on:valuePicked="
                  (data) => {
                    onValuePicked(param.name, data.value)
                  }
                "
              ></driver-action-ui>
            </b-form-group>
          </div>
          <b-button class="my-4" type="submit" variant="success" block>
            Send
          </b-button>
        </b-form>
      </template>
    </sidebar>

    <div v-if="isDataComplete" class="wrap mt-5">
      <div class="profile-info">
        <h2>{{ client.clientName }}</h2>
        <div>
          <b>Status: </b>
          <b :class="'text-' + healthData.variant">{{ healthData.message }}</b>
          <b
            v-if="statusString"
            :class="
              'text-' + statusString.toLowerCase().includes('error')
                ? 'danger'
                : statusString.toLowerCase().includes('unknown')
                  ? 'secondary'
                  : 'success'
            "
            >{{ statusString }}</b
          >
        </div>
        <br />
        <div><b>ID: </b>{{ client.clientId }}</div>
        <div><b>Info: </b>{{ driver.info }}</div>
        <div><b>Alias: </b>{{ driver.alias }}</div>

        <b-alert
          variant="danger"
          class="mt-3 text-center"
          show
          v-if="clientError"
        >
          <b-icon-exclamation-diamond></b-icon-exclamation-diamond>
          <b>Error:</b> {{ clientError }}
        </b-alert>
      </div>
    </div>

    <div v-if="isDataComplete" class="wrap mt-4">
      <div class="profile-info">
        <h3>Methods</h3>
        <b-button
          class="m-1"
          variant="primary"
          v-for="action in driver.signaturesList"
          :key="action.method"
          v-on:click="makeAction(action.method)"
        >
          {{ action.method }}
        </b-button>
      </div>
    </div>

    <div v-if="isDataComplete" class="wrap mt-4">
      <div class="profile-info">
        <h3>Logging</h3>
        <b-form-textarea
          size="sm"
          :id="'log-' + client.clientId"
          rows="25"
          disabled
        ></b-form-textarea>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'control',
    components: {
      Sidebar: () => import('@/components/Sidebar.vue')
    },

    data () {
      return {
        clientError: null,
        client: null,
        drivers: [],
        topics: [],
        formData: {
          methodName: null,
          form: {}
        },
        driverAlias: null,
        updateStreams: [],
        statusString: null,
        health: null,
        errorTimeout: null,
        destroyed: false
      }
    },

    computed: {
      isDataComplete () {
        return this.client && this.driver
      },
      driver () {
        if (this.client) {
          let driver = this.getDriverByAliasId(
            this.client.driverId + this.driverAlias
          )
          if (driver) {
            return driver
          }
          return this.getDriverById(this.client.driverId)
        }
        return null
      },
      healthData () {
        let message = 'UNKNOWN'
        let variant = 'secondary'

        if (this.health === this.$driverManager.HEALTH_STATUS_SERVING) {
          message = 'SERVING'
          variant = 'success'
        } else if (
          this.health === this.$driverManager.HEALTH_STATUS_NOT_SERVING
        ) {
          message = 'NOT SERVING'
          variant = 'warning'
        } else if (
          this.health === this.$driverManager.HEALTH_STATUS_SERVICE_UNKNOWN
        ) {
          message = 'SERVICE UNKNOWN'
          variant = 'danger'
        }

        return { message: message, variant: variant }
      }
    },

    methods: {
      async updateClients () {
        for (let d of this.$driverManager.clients) {
          if (this.$route.params.client_id === d.clientId) {
            let driver = this.getDriverById(d.driverId)
            let alias = await this.$driverManager.computeDriverAlias(d, driver)
            if (alias) {
              this.driverAlias = alias
            }
            this.client = d
            return
          }
        }
      },

      updateStatus () {
        let statusActionName = this.driver.getHealthEventName()
        if (statusActionName) {
          this.$driverManager
            .sendAction(statusActionName, this.client, this.driver, null)
            .then((status) => {
              if (status) {
                this.health = this.driver.getHealthStatusString(status.status)
              }
            })
            .catch((err) => {
              console.error(err)
            })
        }
      },

      getDriverById (driverId) {
        return this.drivers.find((d) => d.driverId === driverId)
      },

      getDriverByAliasId (aliasId) {
        return this.drivers.find(
          (d) => aliasId.includes(d.driverId) && aliasId.includes(d.alias)
        )
      },

      makeAction (methodName) {
        this.$forceUpdate()

        let paramHandler = this.driver.getEventParameterHandler(methodName)

        if (paramHandler != null) {
          this.formData = {
            methodName: methodName,
            form: {}
          }
          this.$refs.sidebar.open()
        } else {
          this.$refs.sidebar.close()
          this.sendAction(methodName, null)
        }
      },

      makeActionFromForm (evt) {
        evt.preventDefault()
        this.sendAction(this.formData.methodName, this.formData.form)
        this.$refs.sidebar.close()
      },

      sendAction (methodName, parameters) {
        this.logAction(
          'log-' + this.client.clientId,
          methodName,
          parameters ? JSON.stringify(parameters, null, '\t') : null,
          true
        )
        this.$driverManager
          .sendAction(methodName, this.client, this.driver, parameters)
          .then((data) => {
            this.logAction(
              'log-' + this.client.clientId,
              methodName,
              data ? JSON.stringify(data, null, '\t') : null,
              false
            )
          })
          .catch((err) => {
            this.logAction(
              'log-' + this.client.clientId,
              methodName,
              err,
              false
            )
          })
      },

      logAction (actionLogId, actionName, payload, isSending) {
        let today = new Date()
        let date =
          today.getFullYear() +
          '/' +
          '' +
          this.pad(today.getMonth() + 1, 2) +
          '/' +
          '' +
          this.pad(today.getDate(), 2)
        let time =
          this.pad(today.getHours(), 2) +
          ':' +
          '' +
          this.pad(today.getMinutes(), 2) +
          ':' +
          '' +
          this.pad(today.getSeconds(), 2)
        let string = '[' + date + ' ' + time + '] '
        if (isSending) {
          string += '>> '
        } else {
          string += '<< '
        }
        string += actionName
        if (payload != null) {
          string += ' ' + payload
        }
        let textArea = document.getElementById(actionLogId)
        textArea.value += string + '\n'
        textArea.scrollTop = textArea.scrollHeight
      },

      pad (num, size) {
        let s = num + ''
        while (s.length < size) s = '0' + s
        return s
      },

      onValuePicked (paramName, value) {
        this.$set(this.formData.form, paramName, value)
      },

      onSidebarClosed () {
        this.formData = {
          methodName: null,
          client: null,
          driver: null,
          form: {}
        }
      },

      onStatusUpdate (data) {
        if (data.clientId !== this.client.clientId) {
          return
        }

        if (!data.status.isHealthStatus) {
          this.statusString = data.status.uiStatus
          if (data.status.logStatus) {
            this.logAction(
              'log-' + this.client.clientId,
              'Status update',
              JSON.stringify(data.status.logStatus, null, '\t'),
              false
            )
          }
        }
      }
    },

    created () {
      this.$driverManager
        .updateBrokerData()
        .then(async () => {
          this.drivers = this.$driverManager.drivers
          await this.updateClients()
          this.$driverManager.$on('client-update', this.onStatusUpdate)
          this.updateStatus()
        })
        .catch((error) => {
          console.log(error)
          this.clientError = error
        })
    },

    beforeDestroy () {
      this.$driverManager.$off('client-update', this.onStatusUpdate)
    }
  }
</script>

<style lang="scss" scoped>
  .wrap {
    max-width: 768px;
    margin: 0px auto;
  }

  .profile-info {
    border-radius: 6px;
    box-shadow: rgb(231 235 238) 0px 0px 0px 1px;
    padding: 20px 30px 30px 30px;
  }
</style>
